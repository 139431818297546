$background: #e4e3ef;
$default-color:#1c273c;
$color:#e5ebf3;
$border: #edecf7;

/*Color variables*/
$primary:var(--primary-bg-color);
$primary-hover:var(--primary-bg-hover);
$primary-border:var(--primary-bg-border);
$primary-01: var(--primary01);
$primary-006: var(--primary006);
$primary-015: var(--primary015);
$primary-09: var(--primary09);
$secondary:#007bff;
$pink:#f10075;
$teal:#00cccc;
$purple:#6f42c1;
$success:#08c18d;
$warning:#ffc107;
$danger:#ff5959;
$info:#17a2b8;
$orange:#fd7e14;
$dark:#3b4863;
$indigo:#b057bf;
$white:#fff;
$black:#000;
$lime:#7bd235;
$gray:#868e96;
$blue:#467fcf;
$azure:#1594ef;

/*gradient variables*/
$bg-primary-gradient:linear-gradient(135deg, $primary 0%, #8039ca 100%);

/*gray variables*/
$gray-100:#f2f1f9;
$gray-200:#e7e6f1;
$gray-300:#dbdae6;
$gray-400:#c3c1d4;
$gray-500:#b1afc1;
$gray-600:#a09eaf;
$gray-700:#8b899a;
$gray-800:#676673;
$gray-900:#494750;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow:5px 7px 12px -5px #9f9fbb;


/* Dark theme */
$dark-theme:var(--dark-theme);
$dark-body: var(--dark-body);
$dark-color: #fff;
$dark-border: rgba(255, 255, 255, 0.1);



	

