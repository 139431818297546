.morris-hover {
    position: absolute;
    z-index: 1000;
  
    &.morris-default-style {
      border-radius: 10px;
      padding: 6px;
      color: #666;
      background: $white-8;
      border: solid 2px rgba(230, 230, 230, 0.8);
      font-family: sans-serif;
      font-size: 12px;
      text-align: center;
  
      .morris-hover-row-label {
        font-weight: bold;
        margin: 0.25em 0;
      }
  
      .morris-hover-point {
        white-space: nowrap;
        margin: 0.1em 0;
      }
    }
  }