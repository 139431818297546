.timeline {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
  
	*, :after, :before {
	  -webkit-box-sizing: inherit;
	  box-sizing: inherit;
	}
  
	&:not(.timeline--horizontal):before {
	  background-color: #d1d4e4;
	  bottom: 0;
	  content: '';
	  left: 50%;
	  margin-left: -2px;
	  position: absolute;
	  top: 0;
	  width: 4px;
	  z-index: 1;
	}
  }
  
  .timeline__wrap {
	overflow: hidden;
	position: relative;
	z-index: 1;
  }
  
  .timeline__item {
	font-size: 16px;
	font-size: 1rem;
	padding: .625rem 2.5rem .625rem 0;
	position: relative;
	width: 50%;
	z-index: 1;
  
	&:after {
	  background-color: $white;
	  border: 4px solid #d1d4e4;
	  border-radius: 50%;
	  content: '';
	  height: 20px;
	  position: absolute;
	  right: -10px;
	  -webkit-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  transform: translateY(-50%);
	  top: 50%;
	  width: 20px;
	  z-index: 1;
	}
  
	&.animated {
	  -webkit-animation-duration: 1s;
	  animation-duration: 1s;
	  -webkit-animation-fill-mode: both;
	  animation-fill-mode: both;
	  opacity: 0;
	}
  
	&.fadeIn {
	  -webkit-animation-name: fadeIn;
	  animation-name: fadeIn;
	}
  }
  
  .timeline__item--left {
	left: 0;
  }
  
  .timeline__item--right {
	left: 50%;
	padding: 0.625rem 0 0.625rem 2.5rem;
  
	&:after {
	  left: -10px;
	}
  
	.timeline__content {
	  &:before {
		border-bottom: 10px solid transparent;
		border-right: 12px solid #e1e6f1;
		border-left: none;
		border-top: 10px solid transparent;
		left: -12px;
	  }
  
	  &:after {
		border-bottom: 9px solid transparent;
		border-right: 11px solid $white;
		border-left: none;
		border-top: 9px solid transparent;
		left: -10px;
	  }
	}
  }
  
  .timeline__content {
	background-color: $white;
	border-radius: 0px;
	color: #334151;
	border: 1px solid #e1e6f1;
	display: block;
	padding: 1.25rem;
	position: relative;
	box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  
	&:after {
	  content: '';
	  height: 0;
	  position: absolute;
	  -webkit-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  transform: translateY(-50%);
	  top: 50%;
	  width: 0;
	}
  
	&:before {
	  content: '';
	  height: 0;
	  position: absolute;
	  -webkit-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  transform: translateY(-50%);
	  top: 50%;
	  width: 0;
	  border-bottom: 10px solid transparent;
	  border-left: 12px solid #e1e6f1;
	  border-top: 10px solid transparent;
	  right: -12px;
	  z-index: 1;
	}
  
	&:after {
	  border-bottom: 9px solid transparent;
	  border-left: 11px solid $white;
	  border-top: 9px solid transparent;
	  right: -10px;
	  z-index: 2;
	}
  
	h2 {
	  font-size: 1rem;
	  font-weight: 700;
	  margin: 0 0 0.625rem;
	}
  
	p {
	  font-size: 14px;
	  line-height: 1.5;
	  margin-bottom: 10px;
	}
  }
  
  .timeline--horizontal {
	white-space: nowrap;
  
	.timeline-divider {
	  background-color: #d1d4e4;
	  display: block;
	  height: 4px;
	  left: 40px;
	  position: absolute;
	  -webkit-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  transform: translateY(-50%);
	  right: 40px;
	  z-index: 0;
	}
  
	.timeline__items {
	  -webkit-transition: all .8s;
	  -o-transition: all .8s;
	  transition: all .8s;
	  will-change: transform;
	}
  
	.timeline__item {
	  display: inline-block;
	  left: 0;
	  padding: 0 0 2.5rem;
	  position: relative;
	  -webkit-transition: none;
	  -o-transition: none;
	  transition: none;
	  vertical-align: top;
	  white-space: normal;
  
	  &:after {
		left: 50%;
		right: auto;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		top: 100%;
	  }
  
	  .timeline__item__inner {
		display: table;
		height: 100%;
		width: 100%;
	  }
  
	  .timeline__content__wrap {
		display: table-cell;
		margin: 0;
		padding: 0;
		vertical-align: bottom;
	  }
  
	  .timeline__content {
		&:before {
		  border-left: 12px solid transparent;
		  border-right: 12px solid transparent;
		  border-top: 12px solid #e1e6f1;
		  left: 50%;
		  right: auto;
		  -webkit-transform: translateX(-50%);
		  -ms-transform: translateX(-50%);
		  transform: translateX(-50%);
		  top: 100%;
		}
  
		&:after {
		  border-left: 10px solid transparent;
		  border-right: 10px solid transparent;
		  border-top: 10px solid $white;
		  left: 50%;
		  right: auto;
		  -webkit-transform: translateX(-50%);
		  -ms-transform: translateX(-50%);
		  transform: translateX(-50%);
		  top: 100%;
		}
	  }
	}
  
	.timeline__item--bottom {
	  padding: 2.5rem 0 0;
  
	  &:after {
		top: 0;
	  }
  
	  .timeline__content__wrap {
		vertical-align: top;
	  }
  
	  .timeline__content {
		&:before {
		  border-bottom: 12px solid #e1e6f1;
		  border-left: 12px solid transparent;
		  border-right: 12px solid transparent;
		  border-top: none;
		  bottom: 100%;
		  top: auto;
		}
  
		&:after {
		  border-bottom: 10px solid $white;
		  border-left: 10px solid transparent;
		  border-right: 10px solid transparent;
		  border-top: none;
		  bottom: 100%;
		  top: auto;
		}
	  }
	}
  }
  
  .timeline-nav-button {
	background-color: $white;
	border: 1px solid #e1e6f1;
	border-radius: 50px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: pointer;
	display: block;
	height: 40px;
	outline: 0;
	position: absolute;
	text-indent: -9999px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	top: 50%;
	width: 40px;
	z-index: 10;
  
	&:disabled {
	  opacity: .5;
	  pointer-events: none;
	}
  
	&:before {
	  background-position: center center;
	  background-repeat: no-repeat;
	  content: '';
	  display: block;
	  height: 14px;
	  left: 50%;
	  position: absolute;
	  -webkit-transform: translateX(-50%) translateY(-50%);
	  -ms-transform: translateX(-50%) translateY(-50%);
	  transform: translateX(-50%) translateY(-50%);
	  top: 50%;
	  width: 8px;
	}
  }
  
  .timeline-nav-button--prev {
	left: 0;
  
	&:before {
	  background-image: url(../images/arrow-left.svg);
	}
  }
  
  .timeline-nav-button--next {
	right: 0;
  
	&:before {
	  background-image: url(../images/arrow-right.svg);
	}
  }
  
  .timeline--mobile {
	padding: 0;
  
	&:before {
	  left: 10px !important;
	  margin: 0 !important;
	}
  
	.timeline__item {
	  left: 0;
	  padding-left: 40px;
	  padding-right: 0;
	  width: 100%;
  
	  &:after {
		left: 2px;
		margin: 0;
	  }
  
	  .timeline__content {
		&:before {
		  left: -12px;
		  border-bottom: 12px solid transparent;
		  border-right: 12px solid #e1e6f1;
		  border-left: none;
		  border-top: 12px solid transparent;
		}
  
		&:after {
		  left: -10px;
		  border-bottom: 10px solid transparent;
		  border-right: 10px solid $white;
		  border-left: none;
		  border-top: 10px solid transparent;
		}
	  }
	}
  }
  
  @-webkit-keyframes fadeIn {
	0% {
	  opacity: 0;
	  top: 70px;
	}
  
	100% {
	  opacity: 1;
	  top: 0;
	}
  }
  
  @keyframes fadeIn {
	0% {
	  opacity: 0;
	  top: 70px;
	}
  
	100% {
	  opacity: 1;
	  top: 0;
	}
  }
  
  @-webkit-keyframes liftUp {
	0% {
	  top: 0;
	}
  
	100% {
	  top: -15px;
	}
  }
  
  @keyframes liftUp {
	0% {
	  top: 0;
	}
  
	100% {
	  top: -15px;
	}
  }
  
  .timeline--horizontal .timeline__item:nth-child(even):after {
	top: 0;
  }