@font-face {
    font-family: summernote;
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(../plugins/summernote/font/summernote.eot);
    src: url(../plugins/summernote/font/summernote.eot?#iefix) format("embedded-opentype"), url(../plugins/summernote/font/summernote.woff2) format("woff2"), url(../plugins/summernote/font/summernote.woff) format("woff"), url(../plugins/summernote/font/summernote.ttf) format("truetype");
  }
  
  [class*=" note-icon"]:before, [class^=note-icon]:before {
    display: inline-block;
    font-family: summernote;
    font-style: normal;
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    speak: none;
  }
  
  .note-icon-fw {
    text-align: center;
    width: 1.25em;
  }
  
  .note-icon-border {
    border: .08em solid #eee;
    border-radius: .1em;
    padding: 0.2em 0.25em 0.15em;
  }
  
  .note-icon-pull-left {
    float: left;
  }
  
  .note-icon-pull-right {
    float: right;
  }
  
  .note-icon {
    &.note-icon-pull-left {
      margin-right: 0.3em;
    }
  
    &.note-icon-pull-right {
      margin-left: 0.3em;
    }
  }
  
  .note-icon-align:before {
    content: "";
  }
  
  .note-icon-align-center:before {
    content: "";
  }
  
  .note-icon-align-indent:before {
    content: "";
  }
  
  .note-icon-align-justify:before {
    content: "";
  }
  
  .note-icon-align-left:before {
    content: "";
  }
  
  .note-icon-align-outdent:before {
    content: "";
  }
  
  .note-icon-align-right:before {
    content: "";
  }
  
  .note-icon-arrow-circle-down:before {
    content: "";
  }
  
  .note-icon-arrow-circle-left:before {
    content: "";
  }
  
  .note-icon-arrow-circle-right:before {
    content: "";
  }
  
  .note-icon-arrow-circle-up:before {
    content: "";
  }
  
  .note-icon-arrows-alt:before {
    content: "";
  }
  
  .note-icon-arrows-h:before {
    content: "";
  }
  
  .note-icon-arrows-v:before {
    content: "";
  }
  
  .note-icon-bold:before {
    content: "";
  }
  
  .note-icon-caret:before {
    content: "";
  }
  
  .note-icon-chain-broken:before {
    content: "";
  }
  
  .note-icon-circle:before {
    content: "";
  }
  
  .note-icon-close:before {
    content: "";
  }
  
  .note-icon-code:before {
    content: "";
  }
  
  .note-icon-col-after:before {
    content: "";
  }
  
  .note-icon-col-before:before {
    content: "";
  }
  
  .note-icon-col-remove:before {
    content: "";
  }
  
  .note-icon-eraser:before {
    content: "";
  }
  
  .note-icon-float-left:before {
    content: "";
  }
  
  .note-icon-float-none:before {
    content: "";
  }
  
  .note-icon-float-right:before {
    content: "";
  }
  
  .note-icon-font:before {
    content: "";
  }
  
  .note-icon-frame:before {
    content: "";
  }
  
  .note-icon-italic:before {
    content: "";
  }
  
  .note-icon-link:before {
    content: "";
  }
  
  .note-icon-magic:before {
    content: "";
  }
  
  .note-icon-menu-check:before {
    content: "";
  }
  
  .note-icon-minus:before {
    content: "";
  }
  
  .note-icon-orderedlist:before {
    content: "";
  }
  
  .note-icon-pencil:before {
    content: "";
  }
  
  .note-icon-picture:before {
    content: "";
  }
  
  .note-icon-question:before {
    content: "";
  }
  
  .note-icon-redo:before {
    content: "";
  }
  
  .note-icon-rollback:before {
    content: "";
  }
  
  .note-icon-row-above:before {
    content: "";
  }
  
  .note-icon-row-below:before {
    content: "";
  }
  
  .note-icon-row-remove:before {
    content: "";
  }
  
  .note-icon-special-character:before {
    content: "";
  }
  
  .note-icon-square:before {
    content: "";
  }
  
  .note-icon-strikethrough:before {
    content: "";
  }
  
  .note-icon-subscript:before {
    content: "";
  }
  
  .note-icon-summernote:before {
    content: "";
  }
  
  .note-icon-superscript:before {
    content: "";
  }
  
  .note-icon-table:before {
    content: "";
  }
  
  .note-icon-text-height:before {
    content: "";
  }
  
  .note-icon-trash:before {
    content: "";
  }
  
  .note-icon-underline:before {
    content: "";
  }
  
  .note-icon-undo:before {
    content: "";
  }
  
  .note-icon-unorderedlist:before {
    content: "";
  }
  
  .note-icon-video:before {
    content: "";
  }
  
  .note-editor {
    position: relative;
  
    .note-dropzone {
      position: absolute;
      display: none;
      z-index: 100;
      color: #87cefa;
      background-color: $white;
      opacity: 0.95;
  
      .note-dropzone-message {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
      }
  
      &.hover {
        color: #098ddf;
      }
    }
  
    &.dragover .note-dropzone {
      display: table;
    }
  
    .note-editing-area {
      position: relative;
  
      .note-editable {
        outline: none;
  
        sup {
          vertical-align: super;
        }
  
        sub {
          vertical-align: sub;
        }
  
        img {
          &.note-float-left {
            margin-right: 10px;
          }
  
          &.note-float-right {
            margin-left: 10px;
          }
        }
      }
    }
  
    &.note-airframe, &.note-frame {
      border: 1px solid #e9edf4;
    }
  
    &.note-airframe.codeview .note-editing-area .note-editable, &.note-frame.codeview .note-editing-area .note-editable {
      display: none;
    }
  
    &.note-airframe.codeview .note-editing-area .note-codable, &.note-frame.codeview .note-editing-area .note-codable {
      display: block;
    }
  
    &.note-airframe .note-editing-area, &.note-frame .note-editing-area {
      overflow: hidden;
    }
  
    &.note-airframe .note-editing-area .note-editable, &.note-frame .note-editing-area .note-editable {
      padding: 10px;
      overflow: auto;
      word-wrap: break-word;
      min-height: 300px;
    }
  
    &.note-airframe .note-editing-area .note-editable[contenteditable=false], &.note-frame .note-editing-area .note-editable[contenteditable=false] {
      background-color: hsla(0, 0%, 50.2%, 0.11);
    }
  
    &.note-airframe .note-editing-area .note-codable, &.note-frame .note-editing-area .note-codable {
      display: none;
      width: 100%;
      padding: 10px;
      border: none;
      box-shadow: none;
      font-family: Menlo, Monaco, monospace, sans-serif;
      font-size: 14px;
      color: #4a4952;
      background-color: #f0f0f5;
      resize: none;
      outline: none;
      -ms-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 0;
      margin-bottom: 0;
    }
  
    &.note-airframe.fullscreen, &.note-frame.fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100% !important;
      z-index: 1050;
    }
  
    &.note-airframe.fullscreen .note-resizebar, &.note-frame.fullscreen .note-resizebar {
      display: none;
    }
  
    &.note-airframe .note-status-output, &.note-frame .note-status-output {
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 1.42857143;
      height: 20px;
      margin-bottom: 0;
      color: #000;
      border: 0;
      border-top: 1px solid #e2e2e2;
    }
  
    &.note-airframe .note-status-output:empty, &.note-frame .note-status-output:empty {
      height: 0;
      border-top: 0 solid transparent;
    }
  
    &.note-airframe .note-status-output .pull-right, &.note-frame .note-status-output .pull-right {
      float: right !important;
    }
  
    &.note-airframe .note-status-output .text-muted, &.note-frame .note-status-output .text-muted {
      color: #777;
    }
  
    &.note-airframe .note-status-output .text-primary, &.note-frame .note-status-output .text-primary {
      color: #286090;
    }
  
    &.note-airframe .note-status-output .text-success, &.note-frame .note-status-output .text-success {
      color: #3c763d;
    }
  
    &.note-airframe .note-status-output .text-info, &.note-frame .note-status-output .text-info {
      color: #31708f;
    }
  
    &.note-airframe .note-status-output .text-warning, &.note-frame .note-status-output .text-warning {
      color: #8a6d3b;
    }
  
    &.note-airframe .note-status-output .text-danger, &.note-frame .note-status-output .text-danger {
      color: #a94442;
    }
  
    &.note-airframe .note-status-output .alert, &.note-frame .note-status-output .alert {
      margin: -7px 0 0;
      padding: 7px 10px 2px;
      border-radius: 0;
      color: #000;
      background-color: #f5f5f5;
    }
  
    &.note-airframe .note-status-output .alert .note-icon, &.note-frame .note-status-output .alert .note-icon {
      margin-right: 5px;
    }
  
    &.note-airframe .note-status-output .alert-success, &.note-frame .note-status-output .alert-success {
      color: #3c763d !important;
      background-color: #dff0d8 !important;
    }
  
    &.note-airframe .note-status-output .alert-info, &.note-frame .note-status-output .alert-info {
      color: #31708f !important;
      background-color: #d9edf7 !important;
    }
  
    &.note-airframe .note-status-output .alert-warning, &.note-frame .note-status-output .alert-warning {
      color: #8a6d3b !important;
      background-color: #fcf8e3 !important;
    }
  
    &.note-airframe .note-status-output .alert-danger, &.note-frame .note-status-output .alert-danger {
      color: #a94442 !important;
      background-color: #f2dede !important;
    }
  
    &.note-airframe .note-statusbar, &.note-frame .note-statusbar {
      background-color: hsla(0, 0%, 50.2%, 0.11);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: 1px solid $black-2;
    }
  
    &.note-airframe .note-statusbar .note-resizebar, &.note-frame .note-statusbar .note-resizebar {
      padding-top: 1px;
      height: 9px;
      width: 100%;
      cursor: ns-resize;
    }
  
    &.note-airframe .note-statusbar .note-resizebar .note-icon-bar, &.note-frame .note-statusbar .note-resizebar .note-icon-bar {
      width: 20px;
      margin: 1px auto;
      border-top: 1px solid $black-2;
    }
  
    &.note-airframe .note-statusbar.locked .note-resizebar, &.note-frame .note-statusbar.locked .note-resizebar {
      cursor: default;
    }
  
    &.note-airframe .note-statusbar.locked .note-resizebar .note-icon-bar, &.note-frame .note-statusbar.locked .note-resizebar .note-icon-bar {
      display: none;
    }
  
    &.note-airframe .note-placeholder, &.note-frame .note-placeholder {
      padding: 10px;
    }
  
    &.note-airframe {
      border: 0;
  
      .note-editing-area .note-editable {
        padding: 0;
      }
    }
  }
  
  .note-popover.popover {
    display: none;
    max-width: none;
  
    .popover-content a {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }
  
    .arrow {
      left: 20px !important;
    }
  }
  
  .note-toolbar {
    position: relative;
  }
  
  .note-editor .note-toolbar, .note-popover .popover-content {
    margin: 0;
    padding: 0 0 5px 5px;
  }
  
  .note-editor .note-toolbar > .note-btn-group, .note-popover .popover-content > .note-btn-group {
    margin-top: 5px;
    margin-left: 0;
    margin-right: 5px;
  }
  
  .note-editor .note-toolbar .note-btn-group .note-table, .note-popover .popover-content .note-btn-group .note-table {
    min-width: 0;
    padding: 5px;
  }
  
  .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker, .note-popover .popover-content .note-btn-group .note-table .note-dimension-picker {
    font-size: 18px;
  }
  
  .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher, .note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute !important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer;
  }
  
  .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted, .note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative !important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat;
  }
  
  .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted, .note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute !important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat;
  }
  
  .note-editor .note-toolbar .note-color-all .note-dropdown-menu, .note-popover .popover-content .note-color-all .note-dropdown-menu {
    min-width: 360px;
  }
  
  .note-editor .note-toolbar .note-color .dropdown-toggle, .note-popover .popover-content .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px;
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette {
    display: inline-block;
    margin: 0;
    width: 160px;
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette:first-child, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette:first-child {
    margin: 0 5px;
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-palette-title, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-palette-title {
    font-size: 12px;
    margin: 2px 7px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette {
    .note-color-reset, .note-color-select {
      font-size: 11px;
      margin: 3px;
      padding: 0 3px;
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
    }
  }
  
  .note-popover .popover-content .note-color .note-dropdown-menu .note-palette {
    .note-color-reset, .note-color-select {
      font-size: 11px;
      margin: 3px;
      padding: 0 3px;
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
    }
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette {
    .note-color-reset:hover, .note-color-select:hover {
      background: #eee;
    }
  }
  
  .note-popover .popover-content .note-color .note-dropdown-menu .note-palette {
    .note-color-reset:hover, .note-color-select:hover {
      background: #eee;
    }
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-row, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-row {
    height: 20px;
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select-btn, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select-btn {
    display: none;
  }
  
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn {
    border: 1px solid #eee;
  }
  
  .note-editor .note-toolbar .note-para .note-dropdown-menu, .note-popover .popover-content .note-para .note-dropdown-menu {
    min-width: 245px;
    padding: 5px;
  }
  
  .note-editor .note-toolbar .note-para .note-dropdown-menu > div + div, .note-popover .popover-content .note-para .note-dropdown-menu > div + div {
    margin-left: 5px;
  }
  
  .note-editor .note-toolbar .note-dropdown-menu, .note-popover .popover-content .note-dropdown-menu {
    min-width: 190px;
    padding: 10px;
    padding-bottom: 0 !important;
  }
  
  .note-editor .note-toolbar .note-dropdown-menu.right, .note-popover .popover-content .note-dropdown-menu.right {
    right: 0;
    left: auto;
  }
  
  .note-editor .note-toolbar .note-dropdown-menu.right:before, .note-popover .popover-content .note-dropdown-menu.right:before {
    right: 9px;
    left: auto !important;
  }
  
  .note-editor .note-toolbar .note-dropdown-menu.right:after, .note-popover .popover-content .note-dropdown-menu.right:after {
    right: 10px;
    left: auto !important;
  }
  
  .note-editor .note-toolbar .note-dropdown-menu.note-check a i, .note-popover .popover-content .note-dropdown-menu.note-check a i {
    color: #00bfff;
    visibility: hidden;
  }
  
  .note-editor .note-toolbar .note-dropdown-menu.note-check a.checked i, .note-popover .popover-content .note-dropdown-menu.note-check a.checked i {
    visibility: visible;
  }
  
  .note-editor .note-toolbar .note-fontsize-10, .note-popover .popover-content .note-fontsize-10 {
    font-size: 10px;
  }
  
  .note-editor .note-toolbar .note-color-palette, .note-popover .popover-content .note-color-palette {
    line-height: 1;
  }
  
  .note-editor .note-toolbar .note-color-palette div .note-color-btn, .note-popover .popover-content .note-color-palette div .note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
  }
  
  .note-editor .note-toolbar .note-color-palette div .note-color-btn:hover, .note-popover .popover-content .note-color-palette div .note-color-btn:hover {
    transform: scale(1.2);
    transition: all 0.2s;
  }
  
  .note-modal {
    .modal-dialog {
      outline: 0;
      border-radius: 5px;
      box-shadow: 0 3px 9px $black-5;
    }
  
    .form-group {
      margin-left: 0;
      margin-right: 0;
    }
  
    .note-modal-form {
      margin: 0;
    }
  
    .note-image-dialog .note-dropzone {
      min-height: 100px;
      font-size: 30px;
      line-height: 4;
      color: #d3d3d3;
      text-align: center;
      border: 4px dashed #d3d3d3;
      margin-bottom: 10px;
    }
  }
  
  @-moz-document url-prefix() {
    .note-modal .note-image-input {
      height: auto;
    }
  }
  
  .note-placeholder {
    position: absolute;
    display: none;
    color: grey;
  }
  
  .note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid #000;
  
    > div {
      position: absolute;
    }
  
    .note-control-selection-bg {
      width: 100%;
      height: 100%;
      background-color: #000;
      -webkit-opacity: .3;
      -khtml-opacity: .3;
      -moz-opacity: .3;
      opacity: .3;
      -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(opacity=30)";
      filter: alpha(opacity = 30);
    }
  
    .note-control-handle, .note-control-holder {
      width: 7px;
      height: 7px;
      border: 1px solid #000;
    }
  
    .note-control-sizing {
      width: 7px;
      height: 7px;
      border: 1px solid #000;
      background-color: #000;
    }
  
    .note-control-nw {
      top: -5px;
      left: -5px;
      border-right: none;
      border-bottom: none;
    }
  
    .note-control-ne {
      top: -5px;
      right: -5px;
      border-bottom: none;
      border-left: none;
    }
  
    .note-control-sw {
      bottom: -5px;
      left: -5px;
      border-top: none;
      border-right: none;
    }
  
    .note-control-se {
      right: -5px;
      bottom: -5px;
      cursor: se-resize;
  
      &.note-control-holder {
        cursor: default;
        border-top: none;
        border-left: none;
      }
    }
  
    .note-control-selection-info {
      right: 0;
      bottom: 0;
      padding: 5px;
      margin: 5px;
      color: $white;
      background-color: #000;
      font-size: 12px;
      border-radius: 5px;
      -webkit-opacity: .7;
      -khtml-opacity: .7;
      -moz-opacity: .7;
      opacity: .7;
      -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(opacity=70)";
      filter: alpha(opacity = 70);
    }
  }
  
  .note-hint-popover {
    min-width: 100px;
    padding: 2px;
  
    .popover-content {
      padding: 3px;
      max-height: 150px;
      overflow: auto;
  
      .note-hint-group .note-hint-item {
        display: block !important;
        padding: 3px;
  
        &.active, &:hover {
          display: block;
          clear: both;
          font-weight: 400;
          line-height: 1.4;
          color: $white;
          white-space: nowrap;
          text-decoration: none;
          background-color: #428bca;
          outline: 0;
          cursor: pointer;
        }
      }
    }
  }
  
  .note-btn {
    &.dropdown-toggle::after {
      display: none;
    }
  
    border-radius: 5px;
  }
  
  .note-editor.note-frame.panel.panel-default .panel-heading {
    background-color: $white;
    border-bottom: 1px solid #e9edf4;
  }
  
  .note-btn.btn-default {
    background-color: $white;
    border-color: #e9edf4;
  }
  
  .note-editor {
    .btn-default:not(:disabled):not(.disabled):active {
      background-color: #f1f3f5;
    }
  
    .btn-group .btn + .btn-group {
      border-left: 0px solid rgba(0, 0, 0, 0.08) !important;
    }
  }
  
  .panel-default.fullscreen .note-editable {
    background-color: $white;
  }
  
  .note-btn {
    &.btn.btn-default.btn-sm {
      line-height: 1.84615385;
    }
  
    &.btn-default:not(:disabled):not(.disabled).active {
      color: #495057;
      background-color: #f1f3f5;
      border-color: #d3d9df;
    }
  }
  
  input {
    &[type="radio"], &[type="checkbox"] {
      margin-right: 7px;
    }
  }
  
  .form-control-file::-webkit-file-upload-button {
    background-color: #e9e9f1;
    transition: none;
    padding: 0.95rem 0.95rem;
    margin: -0.575rem -0.55rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    border: none;
  }