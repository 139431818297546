/* PrismJS 1.17.1
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

 .token {
	color: #800000;
  
	&.comment, &.prolog, &.doctype, &.cdata {
	  color: slategray;
	}
  
	&.punctuation {
	  color: #999;
	}
  }
  
  .namespace {
	opacity: .7;
  }
  
  .token {
	&.property, &.tag, &.boolean, &.number, &.constant, &.symbol, &.deleted {
	  color: #905;
	}
  
	&.selector, &.attr-name, &.string, &.char, &.builtin, &.inserted {
	  color: $secondary;
	}
  
	&.operator, &.entity, &.url {
	  color: #9a6e3a;
	  background: hsla(0, 0%, 100%, 0.5);
	}
  }
  
  .language-css .token.string, .style .token.string {
	color: #9a6e3a;
	background: hsla(0, 0%, 100%, 0.5);
  }
  
  .token {
	&.atrule, &.attr-value, &.keyword {
	  color: #cc0099;
	}
  
	&.function, &.class-name {
	  color: #DD4A68;
	}
  
	&.regex, &.important, &.variable {
	  color: #e90;
	}
  
	&.important, &.bold {
	  font-weight: bold;
	}
  
	&.italic {
	  font-style: italic;
	}
  
	&.entity {
	  cursor: help;
	}
  }