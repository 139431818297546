/*
 * bootstrap-tagsinput v0.8.0
 * 
 */

 .bootstrap-tagsinput {
  background-color: $white;
  display: inline-block;
  color: #777ab2;
  vertical-align: middle;
  border-radius: 3px;
  width: 100%;
  cursor: text;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
  }

  &.form-control input {
    &::-moz-placeholder {
      color: #777;
      opacity: 1;
    }

    &:-ms-input-placeholder, &::-webkit-input-placeholder {
      color: #777;
    }
  }

  input:focus {
    border: none;
    box-shadow: none;
  }

  .badge {
    margin-right: 2px;
    color: white;
    background-color: $primary;
    padding: 5px 8px 7px 8px;
    border-radius: 3px;
    border: 1px solid $primary;

    [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;

      &:after {
        content: "×";
        padding: 0px 4px;
        background-color: $black-3;
        border-radius: 50%;
        font-size: 12px;
      }

      &:hover {
        &:after {
          background-color: $black-2;
        }

        &:active {
          box-shadow: inset 0 3px 5px $black-1;
        }
      }
    }
  }
}