/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */

 .ms-parent {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100% !important;
  }
  
  .ms-choice {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ecf0fa;
    color: #000;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-size: 14px;
    line-height: 13px;
    border-radius: 3px;
  
    &.disabled {
      background-color: #f6f6fb;
      background-image: none;
      border: 1px solid #e9edf4;
      cursor: default;
    }
  
    > {
      span {
        position: absolute;
        top: 13px;
        left: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding-left: 8px;
  
        &.placeholder {
          color: #999;
        }
      }
  
      div {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 20px;
        height: 25px;
        background: url('../plugins/multipleselect/multiple-select.png') left top no-repeat;
  
        &.open {
          background: url('../plugins/multipleselect/multiple-select.png') right top no-repeat;
        }
      }
    }
  }
  
  .ms-drop {
    width: 100%;
    overflow: hidden;
    display: none;
    margin-top: -1px;
    padding: 0;
    position: absolute;
    z-index: 1000;
    background: $white;
    color: #000;
    border: 1px solid #e9edf4;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  
    &.bottom {
      top: 100%;
      box-shadow: 0px 16px 18px rgba(104, 113, 123, 0.2);
    }
  
    &.top {
      bottom: 100%;
      -webkit-box-shadow: 0 -4px 5px rgba(104, 113, 123, 0.2);
      -moz-box-shadow: 0 -4px 5px rgba(104, 113, 123, 0.2);
      box-shadow: 0 -4px 5px rgba(104, 113, 123, 0.2);
    }
  }
  
  .ms-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding: 4px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000;
  }
  
  .ms-parent {
    .placeholder {
      height: 0;
      width: 0;
      border: 0;
    }
  
    &.form-control {
      width: 100% !important;
    }
  }
  
  .ms-search {
    input {
      width: 100%;
      height: auto !important;
      min-height: 24px;
      padding: 0 20px 0 5px;
      margin: 0;
      outline: 0;
      font-family: sans-serif;
      font-size: 1em;
      border: 1px solid #e9edf4;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 7px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background: $white url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px;
      background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, white 85%, #eeeeee 99%);
      background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, white 85%, #eeeeee 99%);
      background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -o-linear-gradient(bottom, white 85%, #eeeeee 99%);
      background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -ms-linear-gradient(top, $white 85%, #eeeeee 99%);
      background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, linear-gradient(top, $white 85%, #eeeeee 99%);
      -webkit-box-sizing: border-box;
      -khtml-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
      box-sizing: border-box;
    }
  
    -webkit-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .ms-drop {
    ul {
      overflow: auto;
      margin: 0;
      padding: 5px 8px;
  
      > li {
        list-style: none;
        display: list-item;
        background-image: none;
        position: static;
  
        .disabled {
          opacity: .35;
          filter: Alpha(Opacity = 35);
        }
  
        &.multiple {
          display: block;
          float: left;
        }
  
        &.group {
          clear: both;
        }
  
        &.multiple label {
          width: 100%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
  
        label {
          font-weight: normal;
          display: block;
          white-space: nowrap;
  
          &.optgroup {
            font-weight: bold;
          }
        }
      }
    }
  
    input {
      &[type="checkbox"] {
        vertical-align: middle;
        vertical-align: middle;
        margin-right: 6px;
        margin-bottom: 1px;
        margin-left: 6px;
      }
  
      &[type="radio"] {
        margin-right: 6px;
        margin-bottom: 1px;
        margin-left: 6px;
        margin-top: 1px;
      }
    }
  
    .ms-no-results {
      display: none;
    }
  
    .multiple {
      width: 90px !important;
    }
  
    ul > li.multiple {
      display: block !important;
      float: left;
    }
  }

  select:disabled{
    opacity: 0;
  }

  .SumoSelect.disabled{
    .form-control:disabled, .form-control[readonly]{
      opacity: 0;
    }
  }
