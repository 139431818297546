/* Container */

.sp-dark {
    &.sp-container {
      background-color: #333;
      border: solid 1px #555;
    }
  
    &.sp-replacer {
      border: solid 1px $white;
      background: #333;
      color: #eee;
      vertical-align: middle;
    }
  }
  
  /* Replacer (the little preview div that shows up instead of the <input>) */
  
  .sp-replacer {
    &:hover, &.sp-active {
      border-color: #F0C49B;
      color: $white;
    }
  
    &.sp-disabled {
      border-color: silver;
      color: silver;
    }
  }
  
  .sp-dark {
    .sp-preview {
      border: solid 1px #999;
    }
  
    .sp-cancel {
      color: #f99f9f !important;
    }
  
    button, input {}
  }
  
  .sp-color, .sp-hue {}
  
  /* Input */
  
  .sp-dark {
    .sp-input-container, .sp-initial-disabled .sp-input-container {}
  
    .sp-input {
      &:focus, &.sp-validation-error {}
    }
  
    .sp-picker-container, .sp-palette-container, .sp-picker-container, .sp-palette-container {}
  
    .sp-palette .sp-thumb-el {
      &:hover, &.sp-thumb-active {}
    }
  
    .sp-thumb-el, .sp-initial span, .sp-button-container {}
  
    &.sp-replacer {
      &:hover, &.sp-active {
        border-color: #F0C49B;
        color: #111;
      }
  
      &.sp-disabled {}
    }
  
    .sp-dd, .sp-preview, .sp-palette .sp-thumb-el {}
  
    button {
      &:hover, &:active {}
    }
  
    .sp-cancel:hover {}
  
    .sp-palette span {
      &:hover, &.sp-thumb-active {}
    }
  }
  
  /* Palettes */
  
  /* Initial */
  
  /* Buttons */
  
  /* Replacer (the little preview div that shows up instead of the <input>) */